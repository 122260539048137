// Add scroll class to Nav when scrolling
var mainNav = document.querySelector('nav');

window.onscroll = function() {
  windowScroll();
};

function windowScroll() {
    mainNav.classList.toggle("scroll", mainNav.scrollTop > 5 || document.documentElement.scrollTop > 5);
}

jQuery(document).ready(function() {

  // function for navigating to specific #
  $('a[href*="#"]').on('click', function(e) {

    if ($(this).attr("href").indexOf('https') != -1) {
      window.location.href = hashHref;
    } else {
      e.preventDefault();

      $('html, body').animate({
        scrollTop: $($(this).attr('href')).offset().top - 90,
      },700,'swing');
    }
    
    return false;
  });

  // Toggle submenu classes
  $('li.menu-item-has-children').on('click', function() {
    $('.menu-item-has-children').toggleClass('__children-active');
    $('.main-nav ul li .sub-menu').toggleClass('__sub-menu-active');
    $('.nav-info').toggleClass('__hide');
  });

  // Mobile menu open function
	$('.menu-icon, .nav-overlay').click(function(e) {
        $('nav').addClass('scroll');
        $('.logo').toggleClass('__logo-invisible');
		    $('.menu-icon').toggleClass('menu-icon-active');
        $('.main-nav').toggleClass('main-nav-active');
        $('.nav-overlay').toggleClass('nav-overlay-active');
		e.preventDefault();
    });

    // Client slider on homepage
    if ($(".clients-slider")[0]){
      $('.clients-slider').slick({
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        prevArrow: $('.cl-prev'),
        nextArrow: $('.cl-next'),
        responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }]
      });
    }
});


  